<template>
  <div class="panel">
    <el-tabs v-model="activeTabName">
      <!-- <el-tab-pane label="Өдрөөр" name="day">
        <day-schedule />
      </el-tab-pane>
      <el-tab-pane label="7 хоногоор" name="week">
        <time-schedule type="week" />
      </el-tab-pane>
      <el-tab-pane label="Сараар" name="month">
        <time-schedule type="month" />
      </el-tab-pane> -->
      <el-tab-pane label="Хүргэгчээр" name="driver">
        <driver-schedule />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
// import DaySchedule from "./components/DaySchedule.vue";
// import TimeSchedule from "./components/TimeSchedule.vue";
import DriverSchedule from "./components/DriverSchedule.vue";
export default {
  name: "DriverTimeSchedule",
  components: {
    // DaySchedule,
    // TimeSchedule,
    DriverSchedule
  },
  data() {
    return {
      activeTabName: "driver"
    };
  }
};
</script>
