<template>
  <div class="panel" style="overflow: scroll; max-height: 800px;">
    <el-row :gutter="20" class="text-right">
      <el-col :span="4">
        <el-date-picker
          v-model="calendar"
          @change="getTimeSchedule(calendar)"
          type="date"
          placeholder="Сонгох"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <br />
    <table style="overflow: scroll; width: 100%;">
      <thead>
        <tr>
          <th>№</th>
          <th>
            <div>
              Цаг
              <hr
                style="border-left: 3px solid green; width:100%; transform: rotate(6deg);"
              />
              Жолооч
            </div>
          </th>
          <th v-for="hour in 24" :key="hour">
            <p>{{ hour - 1 }}</p>
          </th>
        </tr>
      </thead>
      <tbody v-if="driverSchedule.length > 0">
        <tr
          v-for="(currentDriver, currentDriverIndex) in driverSchedule"
          :key="currentDriverIndex"
        >
          <td class="hour-td">{{ currentDriverIndex + 1 }}</td>
          <td class="hour-td">{{ currentDriver.label }}</td>
          <td
            v-for="(currentHour, currentHourIndex) in currentDriver.hours"
            :key="currentHourIndex"
            :class="
              currentHour.checked
                ? 'driverScheduleClass'
                : currentHour.is_cancel
                ? 'driverCancel'
                : ''
            "
          >
            {{ currentHour.hour }}
          </td>
        </tr>
      </tbody>
      <div v-else class="text-center">
        <p>Жолооч байхгүй</p>
      </div>
    </table>
    <p>Нийт {{ driverSchedule.length }}</p>
  </div>
</template>
<script>
import services from "../../../helpers/services";
import dayjs from "dayjs";
export default {
  name: "DriverTimeSchedule",
  mounted() {
    this.calendar = dayjs()
      .add(8, "hour")
      .format("YYYY-MM-DD");
    this.getTimeSchedule(this.calendar);
  },
  data() {
    return {
      file: File,
      loading: false,
      driverSchedule: [],
      calendar: ""
    };
  },
  methods: {
    async getTimeSchedule(date) {
      this.loading = true;
      let chooseDate = null;
      chooseDate = dayjs(date)
        .add(8, "hour")
        .format("YYYY-MM-DD");
      const body = {
        date: chooseDate,
        type: "driver"
      };
      await services
        .Schedule("admin/schedule/getSchedule", body)
        .then(response => {
          if (response.data.status === "success") {
            this.driverSchedule = response.data.data;
            this.loading = false;
          } else {
            this.$message.error({
              title: "Алдаа гарлаа..."
            });
            this.loading = false;
          }
        });
    }
  }
};
</script>
<style scoped>
table thead tr th {
  background-color: #e0e0e0;
  font-weight: normal;
  padding: 5px 0px;
}
table tbody tr td {
  border: 1px solid #e0e0e0;
  text-align: center;
  padding: 5px 0px;
}
table tbody tr td.hour-td {
  background-color: #e0e0e0;
  padding: 5px 5px;
}
.driverScheduleClass {
  background-color: rgb(88, 212, 88);
}
.driverCancel {
  background-color: rgb(255, 0, 0);
}
</style>
